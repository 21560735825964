export const safeRenderHtml = (value: string) =>
  value
    // Fixes <script> tags to be safe
    .replaceAll(/<(\/?)script\b[^>]*>/gi, '<$1illegalscript>')
    // Fixes iframes, objects, and embeds to be responsive
    .replaceAll(
      /<(iframe|object|embed) /gi,
      '<span style="margin: 25px 0; display: block; position: relative; overflow: hidden; padding-top: 56.25%; width: 100%"><$1 style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" '
    )
    .replaceAll(/<\/(iframe|object|embed)>/gi, '</$1></span>')
    // Replaces raw &amp; with & so that it can be used in URLs
    .replaceAll('&amp;', '&')
    .replaceAll(
      '<table align="center" border="1" cellpadding="0" cellspacing="0" id="aui_3_2_0_1109" style="width:1083px">',
      '<table align="center" border="1" cellpadding="0" cellspacing="0" id="aui_3_2_0_1109" style="max-width:90vw; overflow-x: scroll;display: block;white-space: nowrap;">'
    )
    .replaceAll('</table>', '</table></div>');
